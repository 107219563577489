import React from 'react';
import pageStyle from '../../assets/css/page.module.css';
import style from '../../assets/css/main.module.css';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import HeroPage from '../../components/hero.page';
import Hamburger from '../../components/hamburger';

import imgSEO from '../../assets/img/services/developpement.jpg';

import SimilarCard from '../../components/similar.card';

import similar1 from '../../assets/img/services/thumbnail/copywriting.jpg';
import similar2 from '../../assets/img/services/thumbnail/graphicdesign.jpg';
import similar3 from '../../assets/img/services/thumbnail/dev-mobile.jpg';
import similar4 from '../../assets/img/services/thumbnail/conseils.jpg';
import SEO from '../../components/seo';
import cta from '../../assets/img/illustrations/webapp.svg';
import CtaSection from '../../components/cta.section';

export default () => (
  <div id="top">
    <Menu />
    <SEO
      title="Développement web | Agence digitale | MDS Digital Agency"
      description="Quelque soit votre projet de développement web, notre équipe d’experts vous conseille les meilleures solutions techniques."
      keywords="développement web"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgSEO} />

    <article className={pageStyle.article}>
      <h2>Développement web</h2>
      <p>Vous souhaitez ajouter des fonctionnalités à votre site comme par exemple:</p>
      <ul>
        <li>
          Un formulaire de <strong>contact</strong>
        </li>
        <li>
          Une <strong>galerie</strong> de photos ou vidéos
        </li>
        <li>
          Un <strong>calendrier</strong> pour la prise de rendez-vous
        </li>
        <li>
          Un système de <strong>chat</strong>
        </li>
        <li>
          Un <strong>formulaire d’inscription</strong> à une newsletter
        </li>
        <li>
          Des boutons de <strong>partage</strong> pour les réseaux sociaux
        </li>
        <li>
          Une carte <strong>Google Maps</strong>
        </li>
        <li>
          Un <strong>accès clients</strong> ou partenaires sécurisé
        </li>
        <li>
          Un <strong>catalogue</strong> de produits
        </li>
        <li>
          Un module <strong>e-commerce</strong>
        </li>
        <li>
          Une version <strong>multilingue</strong> de votre site web
        </li>
        <li>
          Un système de <strong>réservation en ligne</strong>
        </li>
        <li>
          Un module de <strong>génération de leads</strong>
        </li>
        <li>...</li>
      </ul>
      <p>
        Quelque soit votre projet de développement web, nous vous conseillerons les meilleures{' '}
        <strong>solutions techniques</strong> et notre équipe de développeurs prendra en charge leur{' '}
        <strong>intégration</strong>.
      </p>
      <CtaSection
        img={cta}
        ctaText="Devis en ligne"
        title="Je ne suis pas satisfait de l’état actuel de mon site internet. Pourriez-vous m’aider à optimiser certaines fonctionnalités?"
      />
    </article>
    <div className={pageStyle.footer}>
      <div className={pageStyle.article}>
        <h3>Autres services</h3>
        <div className={pageStyle.footer_flex}>
          <SimilarCard text="Copywriting" url="/services/copywriting/" img={similar1} />
          <SimilarCard text="Graphic Design" url="/services/graphic-design/" img={similar2} />
          <SimilarCard
            text="Développement mobile"
            url="/services/developpement-mobile/"
            img={similar3}
          />
          <SimilarCard text="Conseils" url="/services/conseils/" img={similar4} />
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
